import React from 'react'
import { BackgroundImage, WistiaVideoOverlay } from '@system'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import PlayCircleOutlineIcon from '@react-svgs/play_circle_outline.svg'

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: '0 0 32%',
    },
    thumbnailContainer: {
      position: 'relative',
      textAlign: 'center',
    },
    backgroundImage: {
      paddingTop: '15%',
      minHeight: '140px',
      backgroundPosition: 'top center',
      [theme.breakpoints.down('md')]: {
        minHeight: '90px',
        paddingTop: '10%',
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '67px',
        paddingTop: '5%',
      },
    },
    playIconContainer: {
      height: '100%',
      width: '100%',
    },
    banner: {
      background: theme.palette.primary.main,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightRegular,
      width: '100%',
      justifyContent: 'center',
      height: '35px',
      lineHeight: '35px',
      bottom: 0,
      left: 0,
      right: 0,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }
})

const VideoThumbnailModule = (props) => {
  const classes = useStyles()
  return (
    <SbEditable content={props.blok}>
      <Box className={classes.container}>
        <div className={classes.thumbnailContainer}>
          <BackgroundImage
            className={classes.backgroundImage}
            image={props.blok.image}
          >
            <div className={classes.playIconContainer}>
              <PlayCircleOutlineIcon
                style={{ color: 'white', fontSize: '55px' }}
              />
            </div>
          </BackgroundImage>
          <WistiaVideoOverlay videoId={props.blok.wisitaId} />
        </div>
        <div className={classes.banner}>{props.blok.title}</div>
      </Box>
    </SbEditable>
  )
}

export default VideoThumbnailModule
